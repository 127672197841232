export default {
  recipe: {
    loading: false,
    posting: false,
    data: {}
  },
  recipes: {
    loading: false,
    submitting: false,
    finished: false,
    data: []
  },
  err: null,
}
