export default {
  category: {
    loading: false,
    submitting: false,
    data: {}
  },
  categories: {
    loading: false,
    data: []
  },
  err: null
}
